import React from 'react';
import { Dialog, ViewTable, Button, Status, BookCreateDialog, BookOrderForm, Layout } from '@/components';
import { LibAuthCode } from 'lib/Auth';
import WebApi from '@/web/api';
import LibEnum from 'lib/enum';
import InStockDialog from './InStockDialog';
import { hideLoading, showLoading } from '@/components/message/message';

export default class SaleReturnDetail extends React.PureComponent<RouterPropsType> {

  private bookCreateDialog = React.createRef<BookCreateDialog>();

  state = {
    visible: false,
    activeKey: '1',
    loading: true,
    detailList: [],
    createdAt: '',
    createdByName: '',
    customerName: '',
    orderStatus: '11',
    paymentStatus: '',
    orderStatusDesc: '',
    paymentStatusDesc: '',
    remark: '',
    amount: '',
    paymentRecordList: [],
    paymentModal: false,
  }

  componentDidMount() {
    this.findOrderDetail();
  }

  findOrderDetail = async () => {
    WebApi.sale_return_order_detail({
      saleReturnOrderId: this.props?.match?.params.id,
    })
    .then((data: any) => {
      this.setState({
        loading: false,
        ...data,
      });
    })
  }

  render() {

    const state = this.state;

      return <Layout.Page>
        <Layout.Toolbar title="订货退货单详情">
          {
            state.orderStatus === LibEnum.SALE_ORDER_RETURN_STATUS.WAIT_AUDIT.value &&
            <Button authCode={LibAuthCode.saleReturnOrder.update} type="primary" onClick={this.clickReturnAudit}>审核通过</Button>
          }
          {
            state.orderStatus === LibEnum.SALE_ORDER_RETURN_STATUS.WAIT_IN_STOCK.value &&
            <Button authCode={LibAuthCode.saleReturnOrder.update} type="primary" onClick={this.clickInStock}>商品入库</Button>
          }
          {
            state.paymentStatus === LibEnum.PAYMENT_STATUS_3.WAIT_PAYMENT.value &&
            <Button.Create authCode={LibAuthCode.saleReturnOrder.update} onClick={() => {
              this.bookCreateDialog.current?.open();
            }}>添加付款记录</Button.Create>
          }
        </Layout.Toolbar>
        <div style={{marginBottom: 50}}>
          <Layout.Title>订单信息</Layout.Title>
          <div>
          <div>
            <p>
            <span style={{display: 'inline-block', width: 400}}>
                <strong style={{marginRight: 10}}>订货状态：</strong>
                <Status.Result status={state.orderStatus === LibEnum.SALE_ORDER_RETURN_STATUS.DONE.value ? 'FINISHED' : 'UNFINISHED'}>{state.orderStatusDesc}</Status.Result>
              </span>
              <strong style={{marginRight: 10}}>付款状态：</strong>
              <Status.Result status={state.paymentStatus === LibEnum.PAYMENT_STATUS_3.DONE_PAYMENT.value ? 'FINISHED' : 'UNFINISHED'}>{state.paymentStatusDesc}</Status.Result>
            </p>
            <p>
              <span style={{display: 'inline-block', width: 400}}>
                <strong style={{marginRight: 10}}>退货单号：</strong>
                {this.props.match?.params.id}
              </span>
              <strong style={{marginRight: 10}}>下单时间：</strong>{state.createdAt}
            </p>
            <p>
              <span style={{display: 'inline-block', width: 412}}>
                <strong style={{marginRight: 10}}>客户名称：</strong>{state.customerName}
              </span>
              <strong style={{marginRight: 10}}>制单人：</strong>{state.createdByName}
            </p>
            <p>
              <strong style={{marginRight: 10}}>备注说明：</strong>
              {state.remark}
            </p>
          </div>
          </div>
        </div>
        {/* 退货商品 */}
        <Layout.Title icon="icon-form-shangpin">商品明细</Layout.Title>
        <div style={{marginBottom: 50}}>
          <ViewTable
            pagination={false}
            dataSource={state.detailList}
            rowKey={(row: any) => row.id}
            columns={[
              {
                title: '商品',
                dataIndex: 'productName',
                width: 200,
              },
              {
                title: '规格',
                dataIndex: 'skuName',
                width: 300,
              },
              {
                title: '供应商',
                width: 200,
                dataIndex: 'supplierName',
              },
              {
                title: '进货价格',
                width: 100,
                align: 'right',
                dataIndex: 'purchasePrice',
              },
              {
                title: '退货价格',
                align: 'right',
                dataIndex: 'price',
                width: 100,
              },
              {
                title: '退货数量',
                align: 'right',
                dataIndex: 'count',
                width: 100,
              },
              {
                title: '退款小计',
                align: 'right',
                dataIndex: 'amount',
                width: 100,
              },
              {
                title: '备注',
                align: 'left',
                titleAlign: 'left',
                dataIndex: 'remark',
                width: 240,
              },
          ]}/>
        </div>
      
        <Layout.Title icon="icon-fukuanjilu">付款记录</Layout.Title>
        <BookOrderForm mode="payment" data={state.paymentRecordList}/>

        {
          this.state.visible &&
          <InStockDialog saleReturnOrderId={this.props.match?.params.id} dataSource={this.state.detailList} visible={this.state.visible} onOk={() => {
            this.setState({visible: false});
            this.findOrderDetail();
          }} onCancel={() => this.setState({visible: false})}/>
        }
        <BookCreateDialog ref={this.bookCreateDialog} shouldAmount={state.amount} actualAmount={state.amount} onOk={this.clickPaymentOk}/>
        
      </Layout.Page>
  }

  clickReturnAudit = async () => {
    Dialog.confirm({
      content: '确认通过审核吗？',
      onOk: async () => {
        WebApi.sale_return_order_audit({
          saleReturnOrderId: this.props.match?.params.id,
        })
        .then(() => {
          showSuccess.save();
          this.findOrderDetail();
        });
      }
    });
  }

  clickInStock = async () => {
    this.setState({visible: true});
  }

  clickPaymentOk = async ({actualAmount, datetime, remark}) => {
    showLoading(1);
    WebApi.sale_return_order_pay({
      saleReturnOrderId: this.props.match?.params.id,
      actualAmount,
      datetime,
      remark
    })
    .then(() => {
      this.bookCreateDialog.current?.close();
      showSuccess.save();
      this.findOrderDetail();
    })
    .finally(() => {
      hideLoading();
    })
  }
}