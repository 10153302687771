import React from 'react';
import { message, Select } from 'antd';
import { Dialog, ViewTable } from '@/components';
import WebApi from '@/web/api';

interface ISaleReturnInStockDialogProps {
  saleReturnOrderId: string;
  visible: boolean;
  dataSource: any[];
  onCancel(): void;
  onOk(): void;
}

interface IState {
  loading: boolean;
  submitting: boolean;
  dataSource: any[];
  repositoryList: {repositoryName: string; repositoryId: string}[];
}

export default class SaleReturnInStockDialog extends React.PureComponent<ISaleReturnInStockDialogProps, IState> {

  constructor(props: ISaleReturnInStockDialogProps) {
    super(props);
    this.state = {
      loading: true,
      submitting: false,
      dataSource: props.dataSource,
      repositoryList: [],
    };
  }

  componentDidMount() {
    WebApi.repository_list().then(res => {
      this.setState({
        loading: false,
        repositoryList: res,
        dataSource: this.state.dataSource.map(i => {
          const repo = res.some(r => r.repositoryId === i.repositoryId);
          return {
            ...i,
            selectedRepositoryId: repo ? i.repositoryId : undefined,
            selectedRepositoryName: repo ? i.repositoryName : undefined,
          };
        })
      });
    })
  }

  render() {
    return <Dialog title="填写入库信息"
      visible={this.props.visible}
      okButtonProps={{
        loading: this.state.submitting,
        disabled: this.state.submitting,
      }}
      onCancel={this.props.onCancel}
      onOk={this.clickOk}
    >
      <ViewTable
        pagination={false}
        dataSource={this.state.dataSource}
        columns={[
          {
            title: '商品',
            dataIndex: 'productName',
            width: 200,
          },
          {
            title: '规格',
            dataIndex: 'skuName',
            width: 300,
          },
          {
            title: '供应商',
            dataIndex: 'supplierName',
            width: 200,
          },
          {
            title: '进货价格',
            dataIndex: 'purchasePrice',
            width: 120,
            align: 'right',
          },
          {
            title: '数量',
            dataIndex: 'count',
            width: 120,
            align: 'right',
          },
          {
            title: '选择仓库',
            titleAlign: 'left',
            dataIndex: 'productPrice',
            width: 200,
            render: (v, row, index) => {
              return <Select style={{width: '100%'}}
                value={row.selectedRepositoryId}
                loading={this.state.loading}
                placeholder="选择仓库"
                onChange={(v, option: any) => {
                  const next = [...this.props.dataSource];
                  next[index].selectedRepositoryId = v;
                  next[index].selectedRepositoryName = option.children;
                  this.setState({
                    dataSource: [...this.state.dataSource],
                  });
                }}>
                  {
                    this.state.repositoryList.map(i => {
                      return <Select.Option value={i.repositoryId} key={i.repositoryId}>{i.repositoryName}</Select.Option>
                    })
                  }
                </Select>
            }
          },
        ]}
        rowKey={row => row.purchaseDetailId}
      />
    </Dialog>
  }

  private clickOk = () => {
    for (const item of this.state.dataSource) {
      // 计算count
      if (!item.selectedRepositoryId) {
        message.error(`“${item.productName} ${item.skuName}” 仓库不能为空！`);
        return;
      }
    }
    this.setState({
      submitting: true,
    });
    WebApi.sale_return_order_in_stock({
      saleReturnOrderId: this.props.saleReturnOrderId,
      detailList: this.state.dataSource.map(i => {
        return {
          saleReturnOrderDetailId: i.saleReturnDetailId,
          repositoryId: i.selectedRepositoryId
        };
      })
    })
    .then(() => {
      this.setState({
        submitting: false,
      });
      showSuccess.save();
      this.props.onOk();
    })
    .catch(() => {
      this.setState({
        submitting: false,
      });
    })
  }
}
